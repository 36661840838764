<template>
  <div class="viewPdf">
    <!-- <cpdf :src="'https://mall.oiltour.cn/HW08.pdf'"></cpdf>-->
    <!-- <p><img src="https://mall-api.oiltour.cn/HW08.jpeg" alt="" style="width:97%;height:auto"></p> -->
    <p><img src={{imgUrl}} alt="" style="width:97%;height:auto"></p>
    <div class="item">
      <strong>HW08合同：</strong>
      <p>https://mall-api.oiltour.cn/HW08.pdf</p>
    </div>
    <div class="item">
      <strong>HW18合同：</strong>
      <p>https://mall-api.oiltour.cn/HW18.pdf</p>
    </div>
    <div class="item">
      <strong>HW31合同：</strong>
      <p>https://mall-api.oiltour.cn/HW31.pdf</p>
    </div>
    <!-- <pdf src="https://mall.oiltour.cn/HW08.pdf"></pdf> -->
    <p class="tip">温馨提示：请复制相应合同的url链接在手机默认浏览器打开</p>
  </div>
</template>

<script>
// import pdf from 'vue-pdf'
// import cpdf from '@/components/Cpdf.vue'
import { onMounted} from 'vue'
// import { useRoute } from 'vue-router'
// import { getLocal } from '@/common/js/utils'
export default {
  setup() {
    // const route = useRoute()
    onMounted(async () => {
      // const { imgUrl } = route.params
      // console.log(route)
      // const imgUrl = getLocal('imgUrl')


    })
    return {
      // imgUrl
    }
  },
  components: {
    // cpdf
    // pdf
  },
}
</script>

<style lang="less" scoped>
 @import '../common/style/mixin';
 .viewPdf{
   padding: 10px;
   font-size: 20px;
   .item{
     margin-top: 10px;
   }
   p{
     margin: 0;
     padding: 0;
   }
   .tip{
     margin-top: 12px;
     color: #f90;
     font-size: 18px;
   }
 }
</style>